@import 'var';

.sidebar{
    position: relative;
    height: 45px;
    margin-right: 260px;
    background: $background;

    &.open{
        width: 260px;
        overflow: hidden;
        .menu{
            overflow: visible;
            &__text{
                font-size: 18px;
                font-weight: 700;
                opacity: 1;
                right: 0px;
                width: auto;
            }
            &__link{
                justify-content: flex-start;
                .material-icons{
                    margin: 0 20px;
                }
            }
            &__action{
                display: flex;
                right: 22px;
                opacity: 1;
            }

        }
        .sidebar__toggle{
            justify-content: flex-start;
        }

        .menuFour{
            margin-left: 25px;
            margin-top: 2px;
        }

        li:nth-child(1){
            .menu__text, .menu__action{
                transition:  all .3s ease-in-out;
            }
        }
        li:nth-child(2){
            .menu__text, .menu__action{
                transition:  all .5s ease-in-out;
            }
        }
        li:nth-child(3){
            .menu__text, .menu__action{
                transition:  all .7s ease-in-out;
            }
        }
        li:nth-child(4){
            .menu__text, .menu__action{
                transition:  all .9s ease-in-out;
            }
        }
        li:nth-child(5){
            .menu__text, .menu__action{
                transition:  all 1.1s ease-in-out;
            }
        }

    }

    .logo{
        width: 40px;
        display: block;
        background: url(../images/logo.svg) center center no-repeat;
        margin: 0 auto;
    }

    &__toggle{
        margin-left: 30px;
        background-color: $main;
        float: left;
        width: 55px;
        height: 45px;
        display: flex;
        transition: $transition;
        .menuFour{
            align-self: center;
        }
    }
    .menu{
        display: none;
        margin: 0;
        overflow: hidden;
        &__item{
            display: block;
            position: relative;
        }
        &__link{
            display: flex;
            color: #fff;
            height: 72px;
            justify-content: center;
            transition: $transition;

            &:hover, &.active{
                background-color: #285ACB;
            }
            .material-icons{
                align-self: center;
                position: relative;
                span{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: -8px;
                    right: -8px;
                    color: #fff;
                    background-color: #F36;
                    width: 16px;
                    height: 16px;
                    font-size: 8px;
                    font-family: 'Roboto', sans-serif;
                    font-weight: 300;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    border-radius: 50%;
                }
            }
        }
        &__action{
            position: absolute;
            top: 22px;
            color: #fff;
            width: 30px;
            height: 30px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            opacity: 0;
            right: -50px;
            justify-content: center;
            cursor: pointer;
            transition:  all .5s ease-in-out;
            .material-icons{
                align-self: center;
            }
            &:after{
                content: "";
                display: block;
                width: 15px;
                height: 15px;
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                z-index: 9;
                border: 0;
                -webkit-border-radius: 12px;
                -moz-border-radius: 12px;
                border-radius: 12px;
                transition: $transition;
            }
            &:hover:after{
                width: 30px;
                height: 30px;
                border: 1px solid rgba(255, 255, 255, .5);
                -webkit-border-radius: 25px;
                -moz-border-radius: 25px;
                border-radius: 25px;
                transition: $transition;
            }
        }
        &__text{
            opacity: 0;
            width: 0;
            right: -50px;
            position: relative;
            align-self: center;
        }

    }
}




.menuFour{
    width: 22px;
    height: 22px;
    margin: 10px 17px;
    position: relative;
    cursor: pointer;
    display: inline-block;

}
.menuFour span{
    background-color:#FFF;
    position: absolute;
    border-radius: 2px;
    transition: .3s cubic-bezier(.8, .5, .2, 1.4);
}
.menuFour span:nth-child(1){
    width: 100%;
    height:  2px;
    display: block;
    top: 2px;
    left: 0px;
}
.menuFour span:nth-child(2){
    width: 100%;
    height:  2px;
    display: block;
    top: 10px;
    left: 0px;
}
.menuFour span:nth-child(3){
    width: 100%;
    height:  2px;
    display: block;
    bottom: 2px;
    left: 0px;
}

.clickMenuFour {
    transform: rotate(90deg);
}
.clickMenuFour span:nth-child(1){
    left:3px;
    top: 12px;
    width: 30px;
    transition: .3s cubic-bezier(.8, .5, .2, 1.4);
    transform: rotate(90deg);
    transition-delay: 150ms;
}
.clickMenuFour span:nth-child(2){
    left:2px;
    top: 20px;
    width: 20px;
    transition: .3s cubic-bezier(.8, .5, .2, 1.4);
    transform: rotate(45deg);
    transition-delay: 50ms;
}
.clickMenuFour span:nth-child(3){
    left:14px;
    top: 20px;
    width: 20px;
    transition: .3s cubic-bezier(.8, .5, .2, 1.4);
    transform: rotate(-45deg);
    transition-delay: 100ms;
}


